var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',[_c('el-row',{staticStyle:{"margin-bottom":"20px"},attrs:{"gutter":24}},_vm._l((_vm.statisticData),function(statistic,statisticKey){return _c('el-col',{key:statisticKey,attrs:{"span":8}},[_c('el-statistic',{attrs:{"group-separator":",","precision":2,"value":statistic.value,"title":statistic.name}})],1)}),1),_c('form-create',{attrs:{"inline":true,"label-width":'120px',"components":[
                {name:'form-item',props:{prop:'date',label:'时间',type:'daterange'}},
              ]},on:{"update":function($event){return _vm.handleCommand('whereChange')}},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}}),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"el-button",staticStyle:{"border":"none"}},[_vm._v("收支记录")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$tool.fileResponse('/xapi/user/billDown',_vm.where)}}},[_vm._v("下载列表")])],1),_c('com-table',{ref:"table",attrs:{"columns":[
      {prop:'order_num',label:'订单编号',width:'200' , align:'center'},
      {prop:'nickname',label:'付款人',minWidth:'' , align:'center'},
      {prop:'typeStr',label:'类型',minWidth:'', align:'center'},
      {prop:'goods_title',label:'付款商品',minWidth:'', align:'center'},
      {prop:'create_date',label:'时间',width:'200' , align:'center'},
      {prop:'price',label:'金额（元）',width:'', align:'center'},
    ],"height":480,"data":{
                  url:'/xapi/user/billList'
               },"page-size":_vm.pageSize}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }