<template>
  <el-card>
    <el-row :gutter="24" style="margin-bottom: 20px;">
      <el-col :span="8" v-for="(statistic , statisticKey) in statisticData" :key="statisticKey">
        <el-statistic group-separator="," :precision="2" :value="statistic.value" :title="statistic.name"></el-statistic>
      </el-col>
    </el-row>
    <form-create :inline="true"
                 v-model="where"
                 :label-width="'120px'"
                 :components="[
                  {name:'form-item',props:{prop:'date',label:'时间',type:'daterange'}},
                ]"
                 @update="handleCommand('whereChange')"
    >
    </form-create>
    <div style="display: flex;">
      <div style="border:none;" class="el-button">收支记录</div>
      <el-button type="primary" @click="$tool.fileResponse('/xapi/user/billDown',where)">下载列表</el-button>
    </div>
    <com-table ref="table" :columns="[
        {prop:'order_num',label:'订单编号',width:'200' , align:'center'},
        {prop:'nickname',label:'付款人',minWidth:'' , align:'center'},
        {prop:'typeStr',label:'类型',minWidth:'', align:'center'},
        {prop:'goods_title',label:'付款商品',minWidth:'', align:'center'},
        {prop:'create_date',label:'时间',width:'200' , align:'center'},
        {prop:'price',label:'金额（元）',width:'', align:'center'},
      ]"
               :height="480"
               :data="{
                    url:'/xapi/user/billList'
                 }"
               :page-size="pageSize">
    </com-table>
  </el-card>
</template>

<script>
export default {
  data(){
    return {
      where:{},
      page: 1,
      pageSize:10,
      currentData:{},
      loading:false,
      statisticData:[
        {name:'总收入',value:0},
        {name:'今日收入',value:0},
        {name:'支出',value:0},
      ],
    };
  },
  methods:{
    routeUpdate(){
      this.$http.post('/xapi/user/billStatistic',this.where).then(({data})=>{
        this.statisticData = data || [];
      }).catch();
    },
    handleCommandWhereChange(){
      this.routeUpdate();
      this.$refs.table.reset(this.where);
    }
  }
}
</script>

<style scoped lang="less">
::v-deep{
.el-form-create{
  display: none;
}
  .el-statistic{
    display: inline-flex;
    line-height: 34px;
    .head{
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 0;
    }
    .head:after{
      content: ':';
    }
    .con{
      color: #f00606;
      span{
        line-height: 34px;
      }
    }
    .con:before{
      margin-left: 10px;
      font-size: 16px;
      content: '¥';
    }
  }
}
</style>